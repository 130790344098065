import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		themes: {
			light: {
				primary: '#7C3AED',
				secondary: '#D8B4FE',
				accent: '#A78BFA',
				error: '#EF4444',
				info: '#60A5FA',
				success: '#16A34A',
				warning: '#FBBF24',
			},
		},
	},
})
