import axios from 'axios'
import config from '@/config'

export default {
	install(Vue, { store }) {
		const instance = axios.create({
			baseURL: config.api.baseURL,
			headers: {
				'Content-Type': 'application/json',
				// 'x-auth-token': authToken,
			},
		})

		// To set the updated value of 'x-auth-token' everytime we request from axios
		//Helpful in the cases like when the token expires after some time or changes for some reason
		instance.interceptors.request.use(function (request) {
			let authToken = Vue.$cookies.get('x-auth-token')
			if (authToken != null) {
				request.headers['x-auth-token'] = authToken
			}
			return request
		})

		instance.interceptors.response.use(
			function (response) {
				// Any status code that lie within the range of 2xx cause this function to trigger
				if (response.headers['x-auth-token']) {
					Vue.$cookies.set(
						'x-auth-token',
						response.headers['x-auth-token']
					)
				}

				return response
			},
			function (error) {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				const status = error.response.status || 500
				if (status === 401) {
					Vue.$router.push({ name: 'logout' })
				}
				return Promise.reject(error)
			}
		)

		Vue.prototype.axios = instance
		store.axios = instance
	},
}
