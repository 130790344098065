import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import ability from './ability/index'
import snackbar from './snackbar/index'
import faq from '../domains/Faq/store/index'
import professional from '../domains/Professional/store/index'
import blog from '../domains/Blog/store/index'
import testimony from '../domains/Testimony/store/index'
import project from '../domains/Project/store/index'
import user from '../domains/User/store/index'
import lead from '../domains/Leads/store/index'
import gmb from '../domains/GMB/store/index'
import map from '../domains/Map/store/index'
import report from '../domains/Report/store/index'
import socialMedia from '../domains/SocialMedia/store/index'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	actions: {},
	getters: {},
	mutations: {},
	modules: {
		app,
		ability,
		snackbar,
		faq,
		professional,
		blog,
		testimony,
		project,
		lead,
		user,
		gmb,
		map,
		report,
		socialMedia,
	},
	// strict: process.env.NODE_ENV !== 'production',
})
