export default {
	async getProjectUserListing({ commit, rootState }) {
		await this._vm.axios
			.get(`/project/${rootState.app.projectId}/users`)
			.then((response) => {
				let _data = response.data.data
				commit('user/populateProjectUserListing', _data, { root: true })
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
	editProjectUser({ rootState, commit }, user) {
		this._vm.axios
			.put(`/project/${rootState.app.projectId}/users/${user.id}/roles`, {
				roles: user.roles,
			})
			.then((response) => {
				let _data = response.data
				commit('user/updateProjectUserListing', _data.data, {
					root: true,
				})
				this._vm.$snackbar.notify({
					message: _data.message,
					color: 'success',
				})
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
	removeProjectUser({ rootState, dispatch }, id) {
		this._vm.axios
			.delete(`/project/${rootState.app.projectId}/users/${id}`)
			.then((response) => {
				let _data = response.data
				this._vm.$snackbar.notify({
					message: _data.message,
					color: 'success',
				})

				dispatch('getProjectUserListing', { root: true })
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
	getPendingUserInviteListing({ rootState, commit }) {
		this._vm.axios
			.get(`/project/${rootState.app.projectId}/invites`)
			.then((response) => {
				let _data = response.data.data
				commit('user/populatePendingInviteListing', _data, {
					root: true,
				})
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
	inviteUser({ rootState, commit }, data) {
		this._vm.axios
			.post(`project/${rootState.app.projectId}/invites`, data)
			.then((response) => {
				let _data = response.data
				this._vm.$snackbar.notify({
					message: _data.message,
					color: 'success',
				})
				commit('user/updatePendingInviteListing', _data.data, {
					root: true,
				})
				commit('reset', { root: true })
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
	resendProjectInvite({ rootState }, id) {
		this._vm.axios
			.post(`/project/${rootState.app.projectId}/invites/${id}/resend`)
			.then((response) => {
				let _data = response.data
				if (_data.success) {
					this._vm.$snackbar.notify({
						message: _data.message,
					})
				}
			})
	},
	removePendingUserInvite({ rootState, commit }, id) {
		this._vm.axios
			.delete(`/project/${rootState.app.projectId}/invites/${id}/reject`)
			.then((response) => {
				let _data = response.data
				if (_data.success) {
					this._vm.$snackbar.notify({
						message: _data.message,
						color: 'success',
					})

					commit(
						'user/populatePendingInviteListing',
						_data.data.data,
						{
							root: true,
						}
					)
				}
			})
			.catch((err) => {
				const { data } = err.response
				if (data.success === false) {
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'error',
					})
				}
			})
	},
}
