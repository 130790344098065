import { v4 as uuidv4 } from 'uuid'
export default {
	updatePaginator(state, value) {
		state.paginator = value
	},
	updateProfessionals(state, professionals) {
		state.professionals = professionals
	},
	setIsEdit(state, editStatus) {
		state.isEdit = editStatus
	},
	setIsLoading(state, loadingStatus) {
		state.isLoading = loadingStatus
	},
	saveImage(state, payload) {
		state.banner = payload
		state.bannerId = payload.id
	},
	addNewQualification(state, obj) {
		state.qualifications = [
			...[
				{
					uuid: uuidv4(),
					course: obj.course,
					institute: obj.institute,
					graduationYear: obj.graduationYear,
					fieldOfStudy: '',
				},
			],
			...state.qualifications,
		]
		delete state.errors.qualifications
		state.haveErrors = false
	},
	addNewAward(state, obj) {
		console.log(obj)
		state.awards = [
			...[
				{
					uuid: uuidv4(),
					name: obj.name,
					givenBy: obj.givenBy,
					year: obj.year,
					banner: null,
				},
			],
			...state.awards,
		]
		delete state.errors.awards
		state.haveErrors = false
	},
	addNewPublication(state, obj) {
		state.publications = [
			...[
				{
					uuid: uuidv4(),
					publishedYear: obj.publishedYear,
					name: obj.name,
					journal: obj.journal,
					banner: null,
					url: null,
				},
			],
			...state.publications,
		]
		delete state.errors.publications
		state.haveErrors = false
	},
	addNewExperience(state, obj) {
		state.experiences = [
			...[
				{
					uuid: uuidv4(),
					startedYear: obj.startedYear,
					endedYear: obj.endedYear,
					role: obj.role,
					organisation: obj.organisation,
				},
			],
			...state.experiences,
		]
		delete state.errors.experiences
		state.haveErrors = false
	},
	addNewRegistration(state, obj) {
		state.registrations = [
			...[
				{
					uuid: uuidv4(),
					registrationYear: obj.registrationYear,
					councilName: obj.councilName,
					registrationNumber: obj.registrationNumber,
				},
			],
			...state.registrations,
		]
		delete state.errors.registrations
		state.haveErrors = false
	},
	//   Update Data
	updateQualification(state, emittedobj) {
		let qualifications = state.qualifications
		qualifications.every(function (qualification, index) {
			if (qualification.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					id: emittedobj.id,
					course: emittedobj.course,
					graduationYear: emittedobj.graduationYear,
					institute: emittedobj.institute,
					fieldOfStudy: emittedobj.fieldOfStudy,
				}
				qualifications[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.qualifications = [...qualifications]
		delete state.errors.qualifications
		state.haveErrors = false
	},
	updateAward(state, emittedobj) {
		let awards = state.awards
		awards.every(function (award, index) {
			if (award.uuid === emittedobj.uuid) {
				let obj = {
					uuid: uuidv4(),
					id: emittedobj.id,
					name: emittedobj.name,
					givenBy: emittedobj.givenBy,
					year: emittedobj.year,
					banner: emittedobj.banner,
				}
				awards[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.awards = [...awards]
		delete state.errors.awards
		state.haveErrors = false
	},
	updatePublication(state, emittedobj) {
		let publications = state.publications
		publications.every(function (publication, index) {
			if (publication.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					id: emittedobj.id,
					name: emittedobj.name,
					journal: emittedobj.journal,
					url: emittedobj.url,
					publishedYear: emittedobj.publishedYear,
					description: emittedobj.description,
					banner: emittedobj.banner,
				}
				publications[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.publications = [...publications]
		delete state.errors.publications
		state.haveErrors = false
	},
	updateExperience(state, emittedobj) {
		let experiences = state.experiences
		experiences.every(function (experience, index) {
			if (experience.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					id: emittedobj.id,
					startedYear: emittedobj.startedYear,
					endedYear: emittedobj.endedYear,
					role: emittedobj.role,
					organisation: emittedobj.organisation,
				}
				experiences[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.experiences = [...experiences]
		delete state.errors.experiences
		state.haveErrors = false
	},
	updateRegistration(state, emittedobj) {
		let registrations = state.registrations
		registrations.every(function (registration, index) {
			if (registration.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					id: emittedobj.id,
					registrationYear: emittedobj.registrationYear,
					councilName: emittedobj.councilName,
					registrationNumber: emittedobj.registrationNumber,
				}
				registrations[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.registrations = [...registrations]
		delete state.errors.registrations
		state.haveErrors = false
	},
	updatePersonalData(state, personalData) {
		Object.keys(personalData).forEach((key) => {
			state[key] = personalData[key]
		})
	},
	updateBio(state, bio) {
		state.bio = bio
	},
	updateMemberships(state, emittedobj) {
		state.memberships.push(emittedobj)
	},
	updateServices(state, emittedarr) {
		state.services = emittedarr
	},
	updateContact(state, emittedobj) {
		if (emittedobj.type == 'email') {
			if (emittedobj.email.trim().length > 0) {
				state.emails.push(emittedobj)
			}
		} else if (emittedobj.type == 'phone') {
			if (emittedobj.phone != null) {
				state.phones.push(emittedobj)
			}
		}
	},
	//   Delete Item
	deleteMembership(state, id) {
		let memberships = state.memberships
		memberships.every(function (membership, index) {
			if (membership.uuid === id) {
				memberships.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.memberships = memberships
	},
	deleteQualification(state, id) {
		let qualifications = state.qualifications
		qualifications.every(function (qualification, index) {
			if (qualification.uuid === id) {
				qualifications.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.qualifications = qualifications
	},
	deleteExperience(state, id) {
		let experiences = state.experiences
		experiences.every(function (experience, index) {
			if (experience.uuid === id) {
				experiences.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.experiences = experiences
	},
	deleteAward(state, id) {
		let awards = state.awards
		awards.every(function (award, index) {
			if (award.uuid === id) {
				awards.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.awards = awards
	},
	deletePublication(state, id) {
		let publications = state.publications
		publications.every(function (publication, index) {
			if (publication.uuid === id) {
				publications.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.publications = publications
	},
	deleteRegistration(state, id) {
		let registrations = state.registrations
		registrations.every(function (registration, index) {
			if (registration.uuid === id) {
				registrations.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.registrations = registrations
	},
	deleteEmail(state, id) {
		let emails = state.emails
		emails.every(function (email, index) {
			if (email.uuid === id) {
				emails.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.emails = emails
	},
	deletePhone(state, id) {
		let phones = state.phones
		phones.every(function (phone, index) {
			if (phone.uuid === id) {
				phones.splice(index, 1)
				return false // to break the loop
			} else {
				return true
			}
		})
		state.phones = phones
	},
	//   Errors
	errors(state, errors) {
		let obj = {}
		Object.keys(errors).forEach((key) => {
			obj[key] = errors[key]
		})
		state.errors = { ...obj }
		state.haveErrors = true
	},
	clearErrors(state) {
		state.errors = {}
	},
	//   Initial Data
	populateInitialData(state, page) {
		state.services = page.services
		state.firstName = page.firstName
		state.title = page.title
		state.middleName = page.middleName
		state.lastName = page.lastName
		state.isPublished = page.isPublished
		state.gender = page.gender
		state.practiceStartYear = page.practiceStartYear
		state.bio = page.bio
		state.banner = page.banner
		state.bannerId = page.banner ? page.banner.media : null
		state.memberships = page.memberships.map((obj) => ({
			name: obj.name,
			uuid: uuidv4(),
		}))
		state.qualifications = page.qualifications.map((obj) => ({
			course: obj.course,
			institute: obj.institute,
			graduationYear: obj.graduationYear,
			fieldOfStudy: obj.fieldOfStudy ? obj.fieldOfStudy : '',
			uuid: uuidv4(),
		}))
		state.awards = page.awards.map((obj) => ({
			name: obj.name,
			givenBy: obj.givenBy,
			year: obj.year,
			uuid: uuidv4(),
			banner:
				obj.banner && obj.banner.media
					? {
							id: obj.banner.media,
							publicURL: obj.banner.publicURL,
							altText: obj.banner.altText,
					  }
					: null,
		}))
		state.emails = page.emails.map((str) => ({
			email: str,
			uuid: uuidv4(),
		}))
		state.phones = page.phones.map((number) => ({
			phone: number,
			uuid: uuidv4(),
		}))
		state.experiences = page.experiences.map((obj) => ({
			role: obj.role,
			organisation: obj.organisation,
			startedYear: obj.startedYear,
			endedYear: obj.endedYear,
			uuid: uuidv4(),
		}))
		state.publications = page.publications.map((obj) => ({
			name: obj.name,
			description: obj.description,
			publishedYear: obj.publishedYear,
			journal: obj.journal,
			url: obj.url,
			uuid: uuidv4(),
			banner:
				obj.banner && obj.banner.media
					? {
							id: obj.banner.media,
							publicURL: obj.banner.publicURL,
							altText: obj.banner.altText,
					  }
					: null,
		}))
		state.registrations = page.registrations.map((obj) => ({
			councilName: obj.councilName,
			registrationYear: obj.registrationYear,
			registrationNumber: obj.registrationNumber,
			uuid: uuidv4(),
		}))
		state.id = page.id
	},
	populateListingData(state, data) {
		state.professionals = data.data
		state.paginator = data.paginator
	},
	deleteProfessional(state, professional) {
		state.professionals.every(function (data, index) {
			if (data.id === professional.id) {
				let arr = [...state.professionals]
				arr.splice(index, 1)
				state.professionals = arr
				return false // to break the loop
			} else {
				return true
			}
		})
	},
	showAlert(state, obj) {
		state.alert.show = true
		state.alert.error = obj.haveError
		state.alert.message = obj.message
	},
	clearState(state) {
		state.firstName = null
		state.middleName = null
		state.lastName = null
		state.gender = null
		state.practiceStartYear = null
		state.bio = null
		state.isPublished = false
		state.banner = null
		state.bannerId = null
		state.memberships = []
		state.services = []
		state.qualifications = []
		state.awards = []
		state.registrations = []
		state.experiences = []
		state.publications = []
		state.emails = []
		state.phones = []
		state.errors = {}
		state.haveErrors = false
		state.professionals = []
		state.isLoading = true
		state.isEdit = false
		state.alert.show = null
		state.alert.message = null
		state.alert.error = false
	},
	toggleStatus(state) {
		state.isPublished = !state.isPublished
	},
}
