import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'
export default {
	updatePaginator(state, value) {
		state.paginator = value
	},
	updateReports(state, reports) {
		state.reports = reports
	},
	updateTitle(state, title) {
		state.title = title
	},
	populateListingData(state, data) {
		state.reports = data.data
		state.paginator = data.paginator
	},
	setLocationName(state, locationName) {
		state.gmb.locationName = locationName
	},
	clearState(state) {
		state.reports = []
		state.reportId = null
		state.isEdit = false
		state.timeLine = {
			startDate: null,
			endDate: null,
		}
		state.loading = true
		;(state.title = `Report ${moment().format('DD-MM-YYYY')}`),
			(state.gmb = {
				locationName: null,
				queriesDirect: null,
				queriesIndirect: null,
				viewsMaps: null,
				viewsSearch: null,
				actionsPhone: null,
				actionsDrivingDirection: null,
				actionsWebsite: null,
			}),
			(state.newPages = [])
		state.additionalData = []
	},
	setIsEdit(state, editStatus) {
		state.isEdit = editStatus
	},
	populateInitialData(state, report) {
		state.title = report.title
		state.timeLine = report.timeLine
			? report.timeLine
			: {
					startDate: null,
					endDate: null,
			  }
		state.gmb = report.gmb
			? report.gmb
			: {
					queriesDirect: null,
					queriesIndirect: null,
					viewsMaps: null,
					viewsSearch: null,
					actionsPhone: null,
					actionsDrivingDirection: null,
					actionsWebsite: null,
					locationName: null,
			  }
		state.additionalData = report.additionalData
			? report.additionalData.map((obj) => ({
					heading: obj.heading,
					values: obj.values,
					uuid: uuidv4(),
			  }))
			: []
		state.newPages = report.newPages
			? report.newPages.map((obj) => ({
					url: obj.url,
					createdAt: obj.createdAt,
					uuid: uuidv4(),
			  }))
			: []
		state.loading = false
	},
	updatePages(state, emittedobj) {
		let pages = state.newPages
		pages.every(function (page, index) {
			if (page.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					url: emittedobj.url,
					createdAt: moment(
						emittedobj.createdAt,
						'YYYY-MM-DD'
					).format('DD-MM-YYYY'),
				}
				pages[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.newPages = [...pages]
	},
	addNewPages(state, obj) {
		state.newPages = [
			...[
				{
					uuid: uuidv4(),
					url: obj.url,
					createdAt: obj.createdAt,
				},
			],
			...state.newPages,
		]
	},
	fetchedNewPages(state, arr) {
		state.newPages = arr.map((page) => {
			return {
				...page,
				uuid: uuidv4(),
			}
		})
	},
	updateGMBData(state, obj) {
		state.gmb = {
			queriesDirect: obj.queriesDirect
				? obj.queriesDirect
				: state.gmb.queriesDirect,
			queriesIndirect: obj.queriesIndirect
				? obj.queriesIndirect
				: state.gmb.queriesIndirect,
			viewsMaps: obj.viewsMaps ? obj.viewsMaps : state.gmb.viewsMaps,
			viewsSearch: obj.viewsSearch
				? obj.viewsSearch
				: state.gmb.viewsSearch,
			actionsPhone: obj.actionsPhone
				? obj.actionsPhone
				: state.gmb.actionsPhone,
			actionsDrivingDirection: obj.actionsDrivingDirection
				? obj.actionsDrivingDirection
				: state.gmb.actionsDrivingDirection,
			actionsWebsite: obj.actionsWebsite
				? obj.actionsWebsite
				: state.gmb.actionsWebsite,
			locationName: obj.locationName
				? obj.locationName
				: state.gmb.locationName,
		}
	},
	updateStartDate(state, value) {
		state.timeLine.startDate = value
	},
	updateEndDate(state, value) {
		state.timeLine.endDate = value
	},
	updateAdditionalData(state, emittedobj) {
		let additionalData = state.additionalData
		additionalData.every(function (data, index) {
			if (data.uuid === emittedobj.uuid) {
				let obj = {
					uuid: emittedobj.uuid,
					heading: emittedobj.heading,
					values: emittedobj.values,
				}
				additionalData[index] = obj
				return false // to break the loop
			} else {
				return true
			}
		})
		state.additionalData = [...additionalData]
	},
	addNewAdditionalData(state, obj) {
		state.additionalData = [
			...[
				{
					uuid: uuidv4(),
					heading: obj.heading,
					values: obj.values,
				},
			],
			...state.additionalData,
		]
	},
}
