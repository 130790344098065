export default {
	SET_LOGIN_STATUS(state, status) {
		state.isLoggedIn = status
	},
	SET_FACEBOOK_ACCOUNT(state, facebookAccount) {
		state.facebookAccount = facebookAccount
	},
	SET_FACEBOOK_PAGES(state, facebookPages) {
		state.facebookPages = facebookPages
	},
}
