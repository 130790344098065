export default {
	getHeaders(state) {
		return state.headers
	},
	getLeadData(state) {
		return state.leadList
	},
	getPaginator(state) {
		return state.paginator
	},
	getSelectedLead(state) {
		return state.selectedLead
	},
}
