<template>
	<v-snackbars
		:objects.sync="messageObjects"
		app
		bottom
		right
		width="380"
		elevation="1"
		transition="scroll-y-reverse-transition"
	>
		<template v-slot:default="{ message }">
			{{ message }}
		</template>

		<template v-slot:action="{ close }">
			<v-btn text @click="close()">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbars>
</template>

<script>
import VSnackbars from 'v-snackbars' // https://github.com/Aymkdn/v-snackbars

export default {
	data() {
		return {
			messageObjects: [],
		}
	},
	components: {
		'v-snackbars': VSnackbars,
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'snackbar/notify') {
				this.messageObjects.push({
					message: state.snackbar.message,
					color: state.snackbar.color || 'deep-purple lighten-1',
					timeout: state.snackbar.timeout || 4000,
				})
			}
		})
	},
}
</script>
