<template>
	<v-tabs v-if="tabs.length > 0">
		<v-tab v-for="tab in tabs" :key="tab.index" @click="filterData(tab)">
			{{ tab }}
		</v-tab>
	</v-tabs>
</template>

<script>
export default {
	props: {
		tabs: {
			type: Array,
			default: () => {
				return []
			},
		},
		domain: {
			type: String,
		},
	},
	data() {
		return {
			mine: 'name',
		}
	},
	methods: {
		filterData(tab) {
			const filterName = tab.toLowerCase()
			this.$store.dispatch(this.domain + '/get' + filterName + 'Listing')
		},
	},
}
</script>
