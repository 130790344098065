// import getters from "./getters.js";
import mutations from './mutations.js'
import actions from './actions.js'
const namespaced = true
import moment from 'moment'

export default {
	namespaced,
	state: {
		reports: [],
		paginator: {},
		reportId: null,
		isEdit: false,
		loading: true,
		title: `Report ${moment().format('DD-MM-YYYY')}`,
		timeLine: {
			startDate: null,
			endDate: null,
		},
		gmb: {
			locationName: null,
			queriesDirect: null,
			queriesIndirect: null,
			viewsMaps: null,
			viewsSearch: null,
			actionsPhone: null,
			actionsDrivingDirection: null,
			actionsWebsite: null,
		},
		newPages: [],
		additionalData: [],
	},
	//   getters,
	mutations,
	actions,
}
