import mutations from './mutations.js'

export default {
	namespaced: true,
	state: {
		message: '',
		color: '',
		timeout: '',
	},
	mutations,
}
