const cookies = require('vue-cookies')
import router from '../../../../../router'

export default {
	getLoginStatus(state) {
		return state.isLoggedIn
	},
	getFacebookLoginUrl(state, getters, rootState) {
		const encodedState = Buffer.from(
			JSON.stringify({
				token: cookies.get('x-auth-token'),
				projectId: rootState.app.projectId,
				brandId: router.currentRoute.params.id,
			})
		).toString('base64')

		return `${state.facebookLoginUrl}&state=${encodedState}`
	},
	getFacebookAccount(state) {
		return state.facebookAccount
	},
	getFacebookPages(state) {
		return state.facebookPages
	},
}
