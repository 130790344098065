export default {
	getListingData({ commit, rootState }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/reports`,
			})
			.then((response) => {
				let _data = response.data
				commit('report/populateListingData', _data, {
					root: true,
				})
			})
	},
	deleteReport({ dispatch, rootState }, { reportId }) {
		this._vm.axios
			.delete(`/${rootState.app.projectId}/reports/${reportId}`)
			.then(() => dispatch('getListingData'))
	},

	getInitialData({ commit, rootState }, { id }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/reports/${id}`,
			})
			.then((response) => {
				let _data = response.data.data
				commit('report/populateInitialData', _data, {
					root: true,
				})
			})
	},
	validateAdditionalData: function ({ commit }, { additionalData, isEdit }) {
		let isValid = true
		if (
			additionalData.heading === '' ||
			additionalData.heading === null ||
			additionalData.values.length < 1
		) {
			isValid = false
		}
		if (isValid === true) {
			isEdit
				? commit('report/updateAdditionalData', additionalData, {
						root: true,
				  })
				: commit('report/addNewAdditionalData', additionalData, {
						root: true,
				  })
		}
	},
	validatePageData: function ({ commit }, { pageData, isEdit }) {
		let isValid = true
		if (pageData.url === '' || pageData.createdAt === null) {
			isValid = false
		}
		if (isValid === true) {
			isEdit
				? commit('report/updatePages', pageData, {
						root: true,
				  })
				: commit('report/addNewPages', pageData, {
						root: true,
				  })
		}
	},
	fetchedData: function ({ commit }, { data }) {
		console.log('dagta', data)
		console.log('fdsa', commit)
	},

	validateGMBData: function ({ commit }, { GMBData }) {
		let isValid = true
		if (
			GMBData.queriesDirect === null ||
			GMBData.queriesIndirect === null ||
			GMBData.viewsMaps === null ||
			GMBData.viewsSearch === null ||
			GMBData.actionsPhone === null ||
			GMBData.actionsDrivingDirection === null ||
			GMBData.actionsWebsite === null
		) {
			isValid = false
		}
		if (isValid === true) {
			commit('report/updateGMBData', GMBData, {
				root: true,
			})
		}
	},
}
