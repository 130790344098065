export default {
	getBrandsData({ commit, rootState }) {
		this._vm.axios
			.get(`/${rootState.app.projectId}/social/brands`)
			.then((res) => {
				commit('SET_BRANDS_DATA', res.data.data)
			})
			.catch((err) => {
				console.log(err)
			})
	},
}
