<template>
	<v-app class="bg">
		<!-- Sidebar Navigation Drawer -->
		<Sidebar
			:openDrawer="openDrawer"
			@update-app-drawer-from-sidebar:openDrawer="
				openDrawer = !openDrawer
			"
		></Sidebar>

		<!-- Top Navbar -->
		<Navbar
			:show-filter="showFilter"
			:tabs="tabs"
			:domain="domain"
			@update-app-drawer-from-navbar="openDrawer = !openDrawer"
		></Navbar>

		<v-main>
			<!-- Main Window -->
			<transition name="fade" mode="out-in">
				<router-view
					v-if="$vuetify.breakpoint.mdAndUp || isMobileResponsive"
					class="pb-16"
					:open-sidebar="openDrawer"
					@filter="showFilter = true"
					@tabs="tabs = $event"
					@domain="domain = $event"
				></router-view>

				<section v-else class="box px-4">
					<v-icon color="warning" size="48">
						mdi-alert-decagram-outline
					</v-icon>
					<h1 class="text-h6">View in Desktop</h1>
					<p class="text-subtitle-2 text-center">
						This page is not yet mobile friendly. Please view in
						desktop system to access it.
					</p>
				</section>
			</transition>

			<!-- Footer -->
			<Footer />
		</v-main>

		<!-- Snackbar Notification -->
		<Snackbar />
	</v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Sidebar from '@/components/Sidebar'
import Footer from '@/components/Footer'
import Snackbar from '@/components/Snackbar'

export default {
	components: {
		Navbar,
		Sidebar,
		Footer,
		Snackbar,
	},
	data() {
		return {
			openDrawer: false,
			showFilter: false,
			tabs: [],
			domain: '',
		}
	},
	created() {
		this.openDrawer = this.$vuetify.breakpoint.mdAndUp ? true : false
	},
	computed: {
		isMobileResponsive() {
			// List of route names defined in router files which are mobile responsive
			const listOfMobileResponviveRouteNames = ['map']
			const currentRouteName = this.$route.name
			return listOfMobileResponviveRouteNames.includes(currentRouteName)
		},
	},
	watch: {
		$route: {
			handler() {
				this.getUserAbilities()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		getUserAbilities() {
			const projectId = this.$store.state.app.projectId

			this.axios.get(`/project/${projectId}/abilities`).then((res) => {
				const abilities = res.data.data
				this.$ability.update({ abilities })
			})
		},
	},
}
</script>

<style scoped>
.bg {
	background-color: #f7fafc !important;
}
.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
</style>
