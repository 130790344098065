export default {
	updatePaginator(state, value) {
		state.paginator = value
	},
	updateFaqs(state, faqs) {
		state.faqs = faqs
	},
	populateListingData(state, data) {
		state.faqs = data.data
		state.paginator = data.paginator
	},
	clearState(state) {
		state.faqs = []
		state.paginator = {}
	},
}
