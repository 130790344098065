export default {
	getListingData({ commit, rootState }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/faqs`,
			})
			.then((response) => {
				let _data = response.data
				commit('faq/populateListingData', _data, { root: true })
			})
	},
	deleteFaq({ dispatch, rootState }, { faqId }) {
		this._vm
			.axios({
				method: 'delete',
				url: `/${rootState.app.projectId}/faqs/${faqId}`,
			})
			.then(() => {
				dispatch('getListingData')
			})
	},
}
