import router from '../../../../../router'
import _ from 'lodash'

export default {
	storeAccount({ commit }, { payload }) {
		const decodedState = JSON.parse(
			Buffer.from(payload.state, 'base64').toString('ascii')
		)

		this._vm.axios
			.post(
				`/${decodedState.projectId}/social/brands/${decodedState.brandId}/facebook/account`,
				payload,
				{
					'Content-Type': 'application/json',
				}
			)
			.then((res) => {
				commit('socialMedia/facebook/SET_LOGIN_STATUS', true, {
					root: true,
				})

				commit(
					'socialMedia/facebook/SET_FACEBOOK_PAGES',
					res.data.data.pages,
					{
						root: true,
					}
				)
				router.push({
					path: `/p/${res.data.data.project}/social/brands/${res.data.data.brand}`,
				})
			})
			.catch((err) => {
				this._vm.$snackbar.notify({
					message: err.response.data,
					color: 'error',
				})
			})
	},
	logout({ commit }, { routeParams, facebookAccount }) {
		console.log(facebookAccount)
		const projectId = routeParams.projectId
		const brandId = routeParams.id
		const userId = facebookAccount.userId

		const payload = {
			projectId,
			brandId,
			userId,
		}

		console.log(payload)

		this._vm.axios
			.post(
				`/${projectId}/social/brands/${brandId}/facebook/logout`,
				payload,
				{
					'Content-Type': 'application/json',
				}
			)
			.then((res) => {
				commit('socialMedia/facebook/SET_LOGIN_STATUS', false, {
					root: true,
				})
				commit(
					'socialMedia/facebook/SET_FACEBOOK_ACCOUNT',
					{},
					{
						root: true,
					}
				)
				commit('socialMedia/facebook/SET_FACEBOOK_PAGES', [], {
					root: true,
				})

				this._vm.$snackbar.notify({
					message: res.data.message,
					color: 'success',
				})
			})
			.catch((err) => {
				this._vm.$snackbar.notify({
					message: err.response.data.message,
					color: 'error',
				})
			})
	},
	getSocialAccounts({ commit, rootState }, { payload }) {
		return this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/social/brands/${payload.id}/social-accounts`,
			})
			.then((res) => {
				if (_.has(res, 'data.data.platforms.facebookAccount.pages')) {
					const facebookAccount =
						res.data.data.platforms.facebookAccount
					const facebookPages =
						res.data.data.platforms.facebookAccount.pages

					commit('socialMedia/facebook/SET_LOGIN_STATUS', true, {
						root: true,
					})

					commit(
						'socialMedia/facebook/SET_FACEBOOK_ACCOUNT',
						facebookAccount,
						{
							root: true,
						}
					)

					commit(
						'socialMedia/facebook/SET_FACEBOOK_PAGES',
						facebookPages,
						{
							root: true,
						}
					)
				} else {
					commit('socialMedia/facebook/SET_LOGIN_STATUS', false, {
						root: true,
					})
					commit(
						'socialMedia/facebook/SET_FACEBOOK_ACCOUNT',
						{},
						{
							root: true,
						}
					)
					commit('socialMedia/facebook/SET_FACEBOOK_PAGES', [], {
						root: true,
					})
				}
			})
			.catch((err) => {
				commit('socialMedia/facebook/SET_LOGIN_STATUS', false, {
					root: true,
				})
				commit(
					'socialMedia/facebook/SET_FACEBOOK_ACCOUNT',
					{},
					{
						root: true,
					}
				)
				commit('socialMedia/facebook/SET_FACEBOOK_PAGES', [], {
					root: true,
				})
				console.log(err.response)
			})
	},
}
