<template>
	<v-navigation-drawer
		app
		v-if="openDrawer"
		:permanent="$vuetify.breakpoint.mdAndUp"
		:temporary="!$vuetify.breakpoint.mdAndUp"
		v-model="localDrawer"
		color="grey darken-4"
	>
		<!-- UnoSearch Logo -->
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="py-2 px-1">
					<router-link :to="{ name: 'project.list' }">
						<v-img
							max-width="180"
							src="@/assets/img/logo/horizontal-logo.svg"
						/>
					</router-link>
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<!-- List of Projects Menu -->
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="project-button"
					depressed
					color="#39414e"
					v-bind="attrs"
					v-on="on"
				>
					<span
						v-if="activeProjectName === 'Projects'"
						class="label-text"
					>
						{{ getCurrentProjectName }}
					</span>
					<span v-else class="label-text">
						{{ activeProjectName }}
					</span>
					<v-icon small>mdi-chevron-down</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item
					class="project-names"
					v-for="project in projects"
					:key="project.id"
					@click="changeProject(project.id, project.name)"
				>
					<v-list-item-title>
						{{ project.name }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<!-- Main Menu -->
		<v-list nav dense shaped>
			<!-- Dashboard Button -->
			<v-list-item :to="{ name: 'dashboard' }">
				<v-list-item-icon>
					<v-icon color="grey lighten-2" dense>mdi-home</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="grey--text text--lighten-2">
						Dashboard
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider class="mt-6 mb-3 mx-4"></v-divider>

			<!-- Website Menu -->
			<!-- <v-subheader inset class="grey--text text--lighten-1">
				WEBSITE
			</v-subheader>

			<v-list-group
				v-for="(menu, i) in websiteSidebarMenus"
				:key="i"
				active-class="deep-purple darken-2"
				no-action
			>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title class="grey--text text--lighten-2">
							{{ menu.title }}
						</v-list-item-title>
					</v-list-item-content>
				</template>

				<template v-slot:prependIcon>
					<v-icon color="grey lighten-2" dense>
						{{ menu.icon }}
					</v-icon>
				</template>

				<template v-slot:appendIcon>
					<v-icon color="grey lighten-2" dense>
						mdi-chevron-down
					</v-icon>
				</template>

				<div v-for="(submenu, j) in menu.subMenus" :key="j">
					<v-list-item
						v-if="
							$can(submenu.ability.action, submenu.ability.domain)
						"
						:to="{ name: submenu.path }"
						dense
						active-class="grey darken-3"
						class="list-item"
					>
						<v-list-item-title class="grey--text text--lighten-2">
							{{ submenu.title }}
						</v-list-item-title>
					</v-list-item>
				</div>
			</v-list-group>

			<v-divider class="my-3 mx-4"></v-divider> -->

			<!-- Power Tools Menu -->
			<v-subheader inset class="grey--text text--lighten-1">
				POWER TOOLS
			</v-subheader>

			<div v-for="menu in powerToolsSidebarMenu" :key="menu.title">
				<v-list-item
					v-if="$can(menu.ability.action, menu.ability.domain)"
					:to="{ name: menu.path }"
				>
					<v-list-item-icon>
						<v-icon color="grey lighten-2" dense>
							{{ menu.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="grey--text text--lighten-2">
							{{ menu.title }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	props: {
		openDrawer: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			localDrawer: false,
			activeProjectName: 'Projects',
			websiteSidebarMenus: [
				{
					title: 'Website Builder',
					icon: 'mdi-newspaper-variant-multiple',
					subMenus: [
						{
							title: 'Appearance',
							path: 'theme.list',
							ability: { action: 'update-ui', domain: 'Project' },
						},
						{
							title: 'Home',
							path: 'home.edit',
							ability: { action: 'view', domain: 'Page' },
						},
						{
							title: 'About',
							path: 'about-us',
							ability: { action: 'view', domain: 'Page' },
						},
						{
							title: 'Gallery',
							path: 'videos.list',
							ability: { action: 'view', domain: 'Page' },
						},
						{
							title: 'Media Coverage',
							path: 'mediaCoverage.list',
							ability: { action: 'view', domain: 'Page' },
						},
						{
							title: 'Forum',
							path: 'forum.list',
							ability: { action: 'view', domain: 'Page' },
						},
						{
							title: 'Contact Us',
							path: 'contact.create',
							ability: { action: 'view', domain: 'Page' },
						},
					],
				},
				{
					title: 'Manage Content',
					icon: 'mdi-square-edit-outline',
					subMenus: [
						{
							title: 'Blogs',
							path: 'blog.list',
							ability: { action: 'view', domain: 'Blog' },
						},
						{
							title: 'Case Studies',
							path: 'case-study.list',
							ability: { action: 'view', domain: 'CaseStudy' },
						},
						{
							title: 'Services',
							path: 'service.list',
							ability: { action: 'view', domain: 'Service' },
						},
						{
							title: 'Professionals',
							path: 'professional.list',
							ability: { action: 'view', domain: 'Professional' },
						},
						{
							title: 'Testimonials',
							path: 'testimony.list',
							ability: { action: 'view', domain: 'Testimony' },
						},
						{
							title: 'FAQs',
							path: 'faq.list',
							ability: { action: 'view', domain: 'Faq' },
						},
						{
							title: 'Q & A',
							path: 'question-answer.list',
							ability: { action: 'view', domain: 'Blog' },
						},
						{
							title: 'Templates',
							path: 'template.list',
							ability: { action: 'view', domain: 'Template' },
						},
					],
				},
				{
					title: 'SEO Tools',
					icon: 'mdi-tools',
					subMenus: [
						{
							title: 'Content Freshness',
							path: 'content-freshness',
							ability: {
								action: 'update-advance-settings',
								domain: 'Project',
							},
						},
						{
							title: 'Page SEO',
							path: 'seo.edit',
							ability: {
								action: 'update-advance-settings',
								domain: 'Project',
							},
						},
						{
							title: 'Report',
							path: 'report.list',
							ability: {
								action: 'update-advance-settings',
								domain: 'Project',
							},
						},
					],
				},
				{
					title: 'Leads',
					icon: 'mdi-trending-up',
					subMenus: [
						{
							title: 'Leads Table',
							icon: 'mdi-table-account',
							path: 'leads.list',
							ability: {
								action: 'update-advance-settings',
								domain: 'Project',
							},
						},
					],
				},
				{
					title: 'Project',
					icon: 'mdi-cube',
					subMenus: [
						{
							title: 'Users',
							path: 'project.users',
							ability: {
								action: 'view-users',
								domain: 'Project',
							},
						},
						{
							title: 'Roles & Permissions',
							path: 'roles.list',
							ability: { action: 'view', domain: 'Role' },
						},
						{
							title: 'Update Details',
							path: 'project.edit',
							ability: {
								action: 'view-settings',
								domain: 'Project',
							},
						},
						{
							title: 'Manage Domain',
							path: 'domain.edit',
							ability: {
								action: 'view',
								domain: 'Hosting',
							},
						},
						{
							title: 'Payment',
							path: 'payment',
							ability: {
								action: 'view-settings',
								domain: 'Project',
							},
						},
						{
							title: 'Project Audit',
							path: 'projectaudit',
							ability: {
								action: 'update-advance-settings',
								domain: 'Project',
							},
						},
					],
				},
			],
			powerToolsSidebarMenu: [
				// {
				// 	title: 'Social Media Management',
				// 	icon: 'mdi-gamepad-circle-right',
				// 	path: 'brand.home',
				// 	ability: {
				// 		action: 'update-advance-settings',
				// 		domain: 'Project',
				// 	},
				// },
				{
					title: 'Google My Business',
					icon: 'mdi-storefront',
					path: 'gmb.accounts',
					ability: { action: 'view', domain: 'GMB' },
				},
				// {
				// 	title: 'Local Search Ranker',
				// 	icon: 'mdi-map-marker-path',
				// 	path: 'map',
				// 	ability: {
				// 		action: 'update-advance-settings',
				// 		domain: 'Project',
				// 	},
				// },
			],
		}
	},
	created() {
		this.$store.dispatch('app/getProjectListing', { root: true })
	},
	computed: {
		projects() {
			return this.$store.state.app.projects
		},
		getCurrentProjectName() {
			return this.$store.getters['app/getCurrentProjectName']
		},
	},
	watch: {
		openDrawer() {
			this.localDrawer = this.$props.openDrawer
		},
	},
	methods: {
		updateAppDrawer() {
			this.$emit('update-app-drawer-from-sidebar')
		},
		changeProject(projectId, projectName) {
			this.activeProjectName = projectName
			console.log(projectName)
			this.$router.push({
				path: `/p/${projectId}/dashboard`,
				// name: this.$router.currentRoute.name,
			})
			this.$router.go().catch((err) => {
				if (
					err.name !== 'NavigationDuplicated' &&
					!err.message.includes(
						'Avoided redundant navigation to current location'
					)
				) {
					// But print any other errors to the console
					console.log(err)
				}
			})
		},
	},
}
</script>

<style scoped>
.project-button {
	display: flex;
	justify-content: space-between;
	width: 92%;
	height: 44px !important;
	margin: 12px auto;
	color: white;
	border-radius: 4px;
	text-transform: none;
}
.project-button .label-text {
	max-width: 75%;
	text-overflow: ellipsis;
	overflow: hidden;
}
.project-names {
	color: #cbd4db !important;
	text-decoration: none;
	font-size: 14px;
	font-weight: 400;
	padding: 0 16px;
	height: 32px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
/* As we put `v-list-item` inside `div` we need to apply some default style of `v-list-item` again */
.list-item {
	padding-left: 64px;
}
/* Minimize space between icon and content so as to not overflow text title */
.v-list-item .v-list-item__content {
	margin: 0px -8px;
}

/* Increase sidebar menu title text */
.v-list-item__title.grey--text.text--lighten-2 {
	font-size: 14px !important;
}

/* Change menu item hover color and opacity to match with dark bg */
.v-list-item--link::before {
	background-color: #bdbdbd;
}
.theme--light.v-list-item:hover::before {
	opacity: 0.4;
}

/* Change divider color to match with dark bg */
.theme--light.v-navigation-drawer .v-divider {
	border-color: #535252d5;
}
</style>
