const snackbarPlugin = {
	install: (Vue, { store }) => {
		if (!store) {
			throw new Error('Please provide vuex store.')
		}

		Vue.prototype.$snackbar = {
			notify: function ({ message = '', color = '', timeout = '' }) {
				store.commit(
					'snackbar/notify',
					{ message, color, timeout },
					{ root: true }
				)
			},
		}
	},
}
export default snackbarPlugin
