import moment from 'moment'

export default {
	getListingData({ commit, rootState }, page) {
		page = page ? page : 1
		this._vm
			.axios({
				method: 'get',
				url:
					`/${rootState.app.projectId}/leads?page=` +
					page +
					`&perPage=10`,
			})
			.then((response) => {
				let _data = {
					leadList: response.data.data.map((data) => {
						let formattedDate = '-'
						if (data.date) {
							formattedDate = moment(data.date).format(
								'DD-MM-YYYY'
							)
						}
						return {
							id: data.id,
							isArchieve: data.isArchieve,
							message: data.message,
							project: data.project,
							projectUser: data.projectUser,
							tags: data.tags,
							url: data.url,
							date: formattedDate,
						}
					}),
					paginator: response.data.paginator,
				}

				commit('lead/populateListingData', _data, {
					root: true,
				})
			})
	},
}
