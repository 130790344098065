import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
const namespaced = true

export default {
	namespaced,
	state,
	getters,
	mutations,
	actions,
}
