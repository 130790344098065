import getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions.js'

export default {
	namespaced: true,
	state: {
		loading: false,
		markers: [],
		center: null,
		name: '',
		query: '',
		radius: 1,
		localRanks: [],
		selectedLocation: null,
		showResultsModal: false,
		selectLocationModal: true,
	},
	getters,
	mutations,
	actions,
}
