import router from '../../../router'

export default {
	getAccountEmails({ commit, rootState }) {
		return this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/gmb/accounts`,
			})
			.then((response) => {
				commit('gmb/accountEmails', response.data.data.emails, {
					root: true,
				})
			})
	},
	getOrgData({ commit }, { payload }) {
		return this._vm
			.axios({
				method: 'post',
				url: `/gmb/store`,
				data: payload,
			})
			.then((response) => {
				commit('gmb/orgData', response.data.data.orgs, {
					root: true,
				})
				commit('gmb/orgEmail', response.data.data.email, {
					root: true,
				})
				router.push({
					path: `/p/${response.data.data.projectId}/gmb/accounts`,
				})
			})
	},
	addToTestimonials({ state, rootState }, data) {
		const payload = {
			body: data.comment,
			givenBy: data.reviewer.displayName,
			date: data.createTime,
			email: state.selectedAccountEmail,
		}
		return this._vm
			.axios({
				method: 'post',
				url: `/${rootState.app.projectId}/testimonies`,
				data: payload,
			})
			.then((response) => {
				console.log(response.data.message)
			})
	},
}
