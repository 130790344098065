import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
const namespaced = true

import facebook from '../platforms/Facebook/store/index.js'

export default {
	namespaced,
	state,
	getters,
	mutations,
	actions,
	modules: {
		facebook,
	},
}
