// import getters from "./getters.js";
import mutations from './mutations.js'
import actions from './actions.js'
const namespaced = true

export default {
	namespaced,
	state: {
		title: null,
		firstName: null,
		middleName: null,
		lastName: null,
		gender: null,
		practiceStartYear: null,
		bio: null,
		isPublished: false,
		banner: null,
		bannerId: null,
		memberships: [],
		services: [],
		qualifications: [],
		awards: [],
		registrations: [],
		experiences: [],
		publications: [],
		emails: [],
		phones: [],
		errors: {},
		haveErrors: false,
		professionals: [],
		paginator: {},
		isLoading: true,
		isEdit: false,
		alert: {
			show: null,
			message: null,
			error: false,
		},
	},
	//   getters,
	mutations,
	actions,
}
