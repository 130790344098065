export default {
	getProjectListingData({ commit }) {
		this._vm.axios.get('/user/me/projects').then((response) => {
			let _data = response.data.data
			commit('project/populateProjectListingData', _data, {
				root: true,
			})
		})
	},
	getProjectInvitesData({ commit }) {
		this._vm.axios.get('/user/me/invites').then((response) => {
			let _data = response.data.data
			commit('project/populateProjectInvitesData', _data, {
				root: true,
			})
		})
	},
	acceptProjectInvite({ dispatch }, id) {
		this._vm.axios
			.post(`/user/me/invites/${id}/accept`)
			.then((response) => {
				let _data = response.data
				this._vm.$snackbar.notify({
					message: _data.message,
					color: 'success',
				})

				dispatch('getProjectInvitesData', { root: true })

				dispatch('getProjectListingData', { root: true })
			})
	},
	rejectProjectInvite({ dispatch }, id) {
		this._vm.axios
			.delete(`/user/me/invites/${id}/reject`)
			.then((response) => {
				const _data = response.data
				console.log(_data)

				this._vm.$snackbar.notify({
					message: _data.message,
					color: 'success',
				})

				dispatch('getProjectInvitesData', { root: true })
			})
	},
}
