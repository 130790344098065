// import getters from "./getters.js";
import mutations from './mutations.js'
import actions from './actions.js'
const namespaced = true

export default {
	namespaced,
	state: {
		body: null,
		givenBy: null,
		date: null,
		anonimity: false,
		services: [],
		videoURL: null,
		starRating: null,
		isPublished: false,
		id: null,
		paginator: {},
		errors: {
			body: '',
			givenBy: '',
			date: '',
		},
		alert: {
			show: null,
			message: null,
			error: false,
		},
		haveErrors: false,
		todayDate: false,
		isEdit: false,
		testimonies: [],
	},
	//   getters,
	mutations,
	actions,
}
