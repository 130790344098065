import getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions.js'

export default {
	namespaced: true,
	state: {
		user: {
			firstName: null,
			lastName: null,
		},
		projectId: null,
		projects: [],
		lastUpdated: null,
	},
	getters,
	mutations,
	actions,
}
