const cookies = require('vue-cookies')
export default {
	getAuthUrl(state, _getters, rootState) {
		const buff = Buffer.from(
			JSON.stringify({
				token: cookies.get('x-auth-token'),
				projectId: rootState.app.projectId,
			})
		).toString('base64')
		return state.preAuthorizationUrl + buff + state.postAuthorizationUrl
	},
	getEmails(state) {
		return state.emails
	},
	getOrgs(state) {
		return state.orgs
	},
	getReviews(state) {
		return state.reviewData.reviews
	},
	getReplyId(state) {
		return state.reviewData.reviewReply
	},
	getQuestions(state) {
		return state.faqData.questions
	},
	getAnswers(state) {
		return state.faqData.answers
	},
	getQuestionReply(state) {
		return state.faqData.questionReply
	},
	getPosts(state) {
		return state.posts
	},
	getSelectedOrg(state) {
		return state.selectedOrg
	},
	getLocations(state) {
		return {
			locations: state.locationGroupLocations,
			loading: false,
		}
	},
}
