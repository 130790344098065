import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
const namespaced = true
export default {
	namespaced,
	state: {
		headers: [
			{
				text: 'Name',
				sortable: false,
				align: 'start',
				value: 'projectUser.name',
			},
			{
				text: 'Phone',
				sortable: false,
				value: 'projectUser.phoneNumber',
			},
			// { text: 'Phone Verified', value: 'projectUser.phoneVerified' },
			{ text: 'Email', sortable: false, value: 'projectUser.email' },
			// { text: 'Email Verified', value: 'projectUser.emailVerified' },
			{ text: 'Link', value: 'url' },
			{ text: 'Date', sortable: true, value: 'date' },
		],
		leadList: [],
		paginator: [],
		selectedLead: null,
	},
	getters,
	mutations,
	actions,
}
