export default {
	updatePaginator(state, value) {
		state.paginator = value
	},
	updateBlogs(state, blogs) {
		state.blogs = blogs
	},
	populateListingData(state, data) {
		state.blogs = data.data
		state.paginator = data.paginator
	},
	clearState(state) {
		state.blogs = []
		state.paginator = {}
	},
}
