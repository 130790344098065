export default {
	validatePersonalData: function ({ state, commit }, { personalData }) {
		let errors = {
			personal: {},
		}
		let isValid = true
		if (state.firstName === null && state.lastName === null) {
			errors.personal.firstName = 'First Name field should not be empty.'
			isValid = false
		} else {
			errors.personal.firstName = ''
		}
		// if (state.practiceStartYear === null) {
		// 	errors.personal.practiceStartYear =
		// 		'Practice Start Year is required'
		// 	isValid = false
		// } else {
		// 	errors.personal.practicestartYear = ''
		// }
		if (isValid === true) {
			commit('professional/updatePersonalData', personalData, {
				root: true,
			})
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	validateQualificationData: function (
		{ commit },
		{ qualificationData, isEdit }
	) {
		let errors = {
			qualifications: {},
		}
		let isValid = true
		if (
			qualificationData.course === '' ||
			qualificationData.course === null
		) {
			errors.qualifications.course = 'Course field should not be empty.'
			isValid = false
		} else {
			errors.qualifications.course = ''
		}
		// if (qualificationData.graduationYear === null) {
		// 	errors.qualifications.graduationYear =
		// 		'Graduation Year field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.qualifications.graduationYear = ''
		// }
		// if (qualificationData.institute === '') {
		// 	errors.qualifications.institute =
		// 		'Institute field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.qualifications.institute = ''
		// }
		if (isValid === true) {
			isEdit
				? commit(
						'professional/updateQualification',
						qualificationData,
						{
							root: true,
						}
				  )
				: commit(
						'professional/addNewQualification',
						qualificationData,
						{
							root: true,
						}
				  )
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	validateAwardData: function ({ commit }, { awardData, isEdit }) {
		let errors = {
			awards: {},
		}
		let isValid = true
		if (awardData.name === '' || awardData.name === null) {
			errors.awards.name = 'Name field should not be empty.'
			isValid = false
		} else {
			errors.awards.name = ''
		}
		// if (awardData.year === null) {
		// 	errors.awards.year = 'Year field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.awards.year = ''
		// }
		// if (awardData.givenBy === '') {
		// 	errors.awards.givenBy = 'Given By field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.awards.givenBy = ''
		// }
		if (isValid === true) {
			if (isEdit) {
				commit('professional/updateAward', awardData, {
					root: true,
				})
			} else {
				commit('professional/addNewAward', awardData, {
					root: true,
				})
			}
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	validatePublicationData: function (
		{ commit },
		{ publicationData, isEdit }
	) {
		let errors = {
			publications: {},
		}
		let isValid = true
		// if (publicationData.publishedYear === null) {
		// 	errors.publications.publishedYear =
		// 		'Published Year field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.publications.publishedYear = ''
		// }
		if (publicationData.name === '' || publicationData.name === null) {
			errors.publications.name = 'Name field should not be empty.'
			isValid = false
		} else {
			errors.publications.name = ''
		}
		// if (publicationData.journal === '') {
		// 	errors.publications.journal = 'Journal field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.publications.journal = ''
		// }
		if (isValid === true) {
			isEdit
				? commit('professional/updatePublication', publicationData, {
						root: true,
				  })
				: commit('professional/addNewPublication', publicationData, {
						root: true,
				  })
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	validateExperienceData: function ({ commit }, { experienceData, isEdit }) {
		let errors = {
			experiences: {},
		}
		let isValid = true
		if (experienceData.startedYear === null) {
			errors.experiences.startedYear =
				'Start Year field should not be empty.'
			isValid = false
		} else {
			errors.experiences.startedYear = ''
		}
		if (experienceData.endedYear === null) {
			errors.experiences.endedYear = 'End Year field should not be empty.'
			isValid = false
		} else {
			errors.experiences.endedYear = ''
		}
		if (experienceData.endedYear < experienceData.startedYear) {
			errors.experiences.endedYear = 'Enter valid End Year'
			isValid = false
		} else {
			errors.experiences.endedYear = ''
		}
		if (experienceData.role === '') {
			errors.experiences.role = 'Role field should not be empty.'
			isValid = false
		} else {
			errors.experiences.role = ''
		}
		// if (experienceData.organisation === '') {
		// 	errors.experiences.organisation =
		// 		'Organisation field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.experiences.organisation = ''
		// }
		if (isValid) {
			isEdit
				? commit('professional/updateExperience', experienceData, {
						root: true,
				  })
				: commit('professional/addNewExperience', experienceData, {
						root: true,
				  })
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	validateRegistrationData: function (
		{ commit },
		{ registrationData, isEdit }
	) {
		let errors = {
			registrations: {},
		}
		let isValid = true
		if (registrationData.registrationYear === '') {
			errors.registrations.registrationYear =
				'Registration Year field should not be empty.'
			isValid = false
		} else {
			errors.registrations.registrationYear = ''
		}
		// if (registrationData.councilName === '') {
		// 	errors.registrations.councilName =
		// 		'Council Name field should not be empty.'
		// 	isValid = false
		// } else {
		// 	errors.registrations.councilName = ''
		// }
		if (registrationData.registrationNumber === '') {
			errors.registrations.registrationNumber =
				'Registration Number field should not be empty.'
			isValid = false
		} else {
			errors.role = ''
		}
		if (isValid === true) {
			isEdit
				? commit('professional/updateRegistration', registrationData, {
						root: true,
				  })
				: commit('professional/addNewRegistration', registrationData, {
						root: true,
				  })
		} else {
			commit('professional/errors', errors, { root: true })
		}
	},
	//   INITIAL DATA REQUEST
	saveProfessional: function ({ state, commit, rootState }) {
		let payload = {
			firstName: state.firstName === null ? null : state.firstName,
			title: state.title ? state.title : 'None',
			middleName: state.middleName,
			lastName: state.lastName,
			gender: state.gender,
			practiceStartYear: state.practiceStartYear,
			bio: state.bio,
			banner: state.bannerId,
			memberships: state.memberships.map((obj) => ({ name: obj.name })),
			services: state.services.map((obj) => {
				return obj['id'] ? obj['id'] : obj
			}),
			qualifications: state.qualifications.map((obj) => ({
				course: obj.course,
				institute: obj.institute,
				graduationYear: obj.graduationYear,
				fieldOfStudy: obj.fieldOfStudy ? obj.fieldOfStudy : null,
			})),
			awards: state.awards.map((obj) => ({
				name: obj.name,
				givenBy: obj.givenBy,
				year: obj.year,
				banner:
					obj.banner && obj.banner.publicURL ? obj.banner.id : null,
			})),
			emails: state.emails.map((email) => {
				return email.email
			}),
			phones: state.phones.map((phone) => {
				return phone.phone
			}),
			experiences: state.experiences.map((obj) => ({
				role: obj.role,
				organisation: obj.organisation,
				startedYear: obj.startedYear,
				endedYear: obj.endedYear,
			})),
			publications: state.publications.map((obj) => ({
				name: obj.name,
				description: obj.description,
				publishedYear: obj.publishedYear,
				journal: obj.journal,
				url: obj.url,
				banner:
					obj.banner && obj.banner.publicURL ? obj.banner.id : null,
			})),
			registrations: state.registrations.map((obj) => ({
				councilName: obj.councilName,
				registrationYear: obj.registrationYear,
				registrationNumber: obj.registrationNumber,
			})),
		}
		this._vm
			.axios({
				method: state.isEdit ? 'put' : 'post',
				url: state.isEdit
					? `/${rootState.app.projectId}/professionals/${state.id}`
					: `/${rootState.app.projectId}/professionals`,
				data: payload,
			})
			.then((response) => {
				let _data = response.data
				if (_data.success && _data.data != null) {
					commit(
						'professional/showAlert',
						{ message: _data.message, haveError: false },
						{ root: true }
					)
					commit('professional/setIsEdit', true, { root: true }) // sets edit mode true
				} else if ('errors' in _data) {
					console.log(_data.errors)
				}
			})
			.catch((error) => {
				const { data } = error.response
				if (data.statusCode == 400) {
					this.isSaving = false
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'black',
					})
				}
				// let errors = error.response.data
				// commit(
				// 	'professional/showAlert',
				// 	{ message: errors.message, haveError: true },
				// 	{ root: true }
				// )
			})
	},
	getInitialData: function ({ commit, rootState }, { id }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/professionals/${id}`,
			})
			.then((response) => {
				let _data = response.data.data
				commit('professional/populateInitialData', _data, {
					root: true,
				})
			})
			.then(() => {
				commit('professional/setIsLoading', false, { root: true })
			})
	},
	getProfessionalListingData({ commit, rootState }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/professionals`,
			})
			.then((response) => {
				let _data = response.data
				commit('professional/populateListingData', _data, {
					root: true,
				})
			})
	},
	toggleStatus: function ({ commit, rootState }, state) {
		return this._vm
			.axios({
				method: 'post',
				url: `/${rootState.app.projectId}/professionals/toggle-status/${state.id}`,
			})
			.then(() => {
				commit('professional/toggleStatus', null, {
					root: true,
				})
			})
	},
	deleteProfessional({ commit, rootState }, { professionalId }) {
		this._vm
			.axios({
				method: 'delete',
				url: `/${rootState.app.projectId}/professionals/${professionalId}`,
			})
			.then((response) => {
				let _data = response.data.data
				commit('deleteProfessional', _data)
			})
	},
}
