export default {
	getRadius(state) {
		return state.radius
	},
	getQuery(state) {
		return state.query
	},
	getCenter(state) {
		return state.center
	},
	getMarkers(state) {
		return state.markers
	},
	isLoading(state) {
		return state.loading
	},
	getLocalRanks(state) {
		return state.localRanks
	},
	getSelectedLocation(state) {
		return state.selectedLocation
	},
	getResultsModal(state) {
		return state.showResultsModal
	},
}
