export default {
	updateProjectUser(state, obj) {
		Object.keys(obj).forEach((key) => {
			state[key] = obj[key]
		})
	},
	updateProjectUserListing(state, obj) {
		const arr = state.projectUsers
		const userIndex = arr.findIndex((user) => user.email === obj.email)
		if (userIndex !== -1) {
			arr[userIndex] = obj
		} else {
			arr.push(obj)
		}
		state.projectUsers = arr
	},
	updatePendingInviteListing(state, obj) {
		const arr = state.pendingUserInvites
		arr.push(obj)
		state.pendingUserInvites = arr
	},
	populateProjectUserListing(state, listingArray) {
		state.projectUsers = listingArray
	},
	populatePendingInviteListing(state, listingArray) {
		state.pendingUserInvites = listingArray
	},
	reset(state) {
		state.userEmail = ''
		state.userRoles = []
	},
}
