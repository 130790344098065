export default {
	populateListingData(state, listingArray) {
		state.leadList = listingArray.leadList
		state.paginator = listingArray.paginator
	},
	populateSelectedLead(state, lead) {
		state.selectedLead = lead
	},
	nullifySelectedLead(state) {
		state.selectedLead = null
	},
}
