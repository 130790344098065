import Vue from 'vue'
import vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCookies from 'vue-cookies'
import CKEditor from '@ckeditor/ckeditor5-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios.js'
import snackbarPlugin from './plugins/snackbar'
import abilityPlugin from './plugins/ability'
import { confirmPlugin } from './plugins/confirm'

Vue.use(snackbarPlugin, { store })
Vue.use(abilityPlugin, { store })
Vue.use(confirmPlugin)
Vue.use(VueCookies)
Vue.use(axios, { store })
Vue.use(CKEditor)
Vue.use(vuelidate)
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAP_API_TOKEN,
		libraries: 'places',
	},
})

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
