const _ = require('lodash')

export default {
	setUserAndProjects({ commit }) {
		this._vm.axios
			.get('/user/me')
			.then((res) => res.data)
			.then((res) => {
				const user = {
					firstName: res.firstName,
					lastName: res.lastName,
				}
				commit('setUser', user)

				const projects = _.map(res.projects, (project) => {
					return {
						id: project.project.id,
						name: project.project.name,
						roles: project.roles,
					}
				})
				commit('setProjects', projects)
			})
	},
	getProjectListing: function ({ commit }) {
		return this._vm
			.axios({
				method: 'get',
				url: '/user/me/projects',
			})
			.then((response) => {
				let _data = response.data.data
				commit('populateListingData', _data)
			})
	},
}
