// const _ = require('lodash')

export default {
	nameInitialsForAvatar: (state) => {
		let str = ''
		if (state.user.firstName) str += state.user.firstName.charAt(0)

		if (state.user.lastName) str += state.user.lastName.charAt(0)

		return str
	},
	getCurrentProject: (state) => {
		let projects = state.projects
		let currentProject = {}
		projects.forEach((project) => {
			if (project.id === state.projectId) {
				return (currentProject = project)
			}
		})
		return currentProject
	},
	getCurrentProjectDomain: (state, getters) => {
		return getters.getCurrentProject.domain
	},
	getCurrentProjectName: (state, getters) => {
		return getters.getCurrentProject.name
	},
}
