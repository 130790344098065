import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layout/AppLayout'
import store from '../store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/p/:projectId',
		component: AppLayout,
		children: [
			//Q&A
			{
				path: 'question-answer',
				name: 'question-answer.list',
				component: () =>
					import('../domains/QuestionAnswer/QuestionAnswer'),
				meta: {
					title: 'Question Answer',
				},
			},
			{
				path: 'question-answer/:id/edit',
				name: 'question-answer.edit',

				component: () => import('../domains/QuestionAnswer/EditAnswer'),
				meta: {
					title: 'Edit Answer',
				},
				props: (route) => ({
					id: route.params.id,
					isEdit: true,
				}),
			},
			//ExpectedRange
			{
				path: 'project-audit',
				name: 'projectaudit',
				component: () => import('../domains/Project/ProjectAudit'),
				meta: {
					title: 'Project Audit',
				},
			},
			//Payment
			{
				path: 'payment',
				name: 'payment',
				component: () => import('../domains/Payment/Payment.vue'),
				meta: {
					title: 'Payment',
				},
			},
			//MAP
			{
				path: 'map',
				name: 'map',
				component: () => import('../domains/Map/LocalSearchRanker.vue'),
				meta: {
					title: 'Local Search Ranker',
				},
			},
			//Dashboard
			{
				path: 'dashboard',
				name: 'dashboard',
				component: () => import('../domains/Dashboard/Dashboard.vue'),
				meta: {
					title: 'Dashboard',
				},
			},
			// Testimonies
			{
				path: 'testimony',
				name: 'testimony.list',
				component: () => import('../domains/Testimony/Testimonies.vue'),
				meta: {
					title: 'Testimony Listing',
				},
			},
			// Services
			{
				path: 'service',
				name: 'service.list',
				component: () => import('../domains/Service/Services.vue'),
				meta: {
					title: 'Service Listing',
				},
			},
			{
				path: 'service/create',
				name: 'service.create',
				component: () => import('../domains/Service/CreateService.vue'),
				meta: {
					title: 'Create Service',
				},
				props: {
					isEdit: false,
				},
			},
			{
				path: 'service/:id/edit',
				name: 'service.edit',
				component: () => import('../domains/Service/CreateService.vue'),
				meta: {
					title: 'Edit Service',
				},
				props: (route) => ({
					id: route.params.id,
					isEdit: true,
				}),
			},
			// CASE STUDY
			{
				path: 'case-study',
				name: 'case-study.list',
				component: () => import('../domains/CaseStudy/CaseStudies.vue'),
				meta: {
					title: 'Case Study Listing',
				},
			},
			{
				path: 'case-study/create',
				name: 'case-study.create',
				component: () =>
					import('../domains/CaseStudy/CreateCaseStudy.vue'),
				meta: {
					title: 'Create CaseStudy',
				},
				props: {
					isEdit: false,
				},
			},
			{
				path: 'case-study/:id/edit',
				name: 'case-study.edit',
				component: () =>
					import('../domains/CaseStudy/CreateCaseStudy.vue'),
				meta: {
					title: 'Edit CaseStudy',
				},
				props: (route) => ({
					id: route.params.id,
					isEdit: true,
				}),
			},

			// ROLES AND PERMISSIONS
			{
				path: 'roles',
				name: 'roles.list',
				component: () => import('../domains/Authorisation/Roles.vue'),
				meta: {
					title: 'Roles and Permissions',
				},
			},
			{
				path: 'roles/create',
				name: 'roles.create',
				component: () =>
					import('../domains/Authorisation/CreateRole.vue'),
				meta: {
					title: 'Create Role',
				},
			},
			{
				path: 'roles/:id/edit',
				name: 'roles.edit',
				component: () =>
					import('../domains/Authorisation/CreateRole.vue'),
				meta: {
					title: 'Edit Role',
				},
				props: (route) => ({ id: route.params.id }),
			},

			//Leads
			{
				path: 'leads',
				name: 'leads.list',
				component: () => import('../domains/Leads/Leads.vue'),
				meta: {
					title: 'Leads Listing',
				},
			},

			//GMB
			{
				path: 'gmb',
				component: () => import('../domains/GMB/GMB.vue'),
				children: [
					{
						path: 'media',
						name: 'gmb.media',
						component: () =>
							import('../domains/GMB/components/Media.vue'),
						meta: {
							title: 'GMB Media',
						},
					},
					{
						path: 'reviews',
						name: 'gmb.reviews',
						component: () =>
							import('../domains/GMB/components/Reviews.vue'),
						meta: {
							title: 'GMB Reviews',
						},
					},

					{
						path: 'qa',
						name: 'gmb.qa',
						component: () =>
							import('../domains/GMB/components/Questions.vue'),
						meta: {
							title: 'GMB Questions & Answers',
						},
					},
					{
						path: 'posts',
						name: 'gmb.posts',
						component: () =>
							import('../domains/GMB/components/Posts.vue'),
						meta: {
							title: 'GMB Posts',
						},
					},
					{
						path: 'scheduled-posts',
						name: 'gmb.scheduled-posts',
						component: () =>
							import(
								'../domains/GMB/components/ScheduledPosts.vue'
							),
						meta: {
							title: 'GMB Scheduled Posts',
						},
					},
					// {
					// 	path: 'summary',
					// 	name: 'gmb.summary',
					// 	component: () =>
					// 		import('../domains/GMB/components/Summary.vue'),
					// 	meta: {
					// 		title: 'GMB Summary',
					// 	},
					// },
					{
						path: 'insights',
						name: 'gmb.insights',
						component: () =>
							import('../domains/GMB/components/Insights.vue'),
						meta: {
							title: 'GMB Insights',
						},
					},
				],
			},
			{
				path: 'gmb/accounts',
				name: 'gmb.accounts',
				component: () =>
					import('../domains/GMB/components/Accounts.vue'),
				meta: {
					title: 'GMB Accounts',
				},
			},
			{
				path: 'gmb/orgs',
				name: 'gmb.orgs',
				component: () =>
					import('../domains/GMB/components/Organisations.vue'),
				meta: {
					title: 'GMB Organizations',
				},
			},
			{
				path: 'gmb/locations',
				name: 'gmb.locations',
				component: () =>
					import('../domains/GMB/components/Locations.vue'),
				meta: {
					title: 'GMB Locations',
				},
			},

			//Social Media Management
			{
				path: 'social',
				name: 'social.home',
				meta: {
					title: 'Social Media Management',
				},
				component: () =>
					import('../domains/SocialMedia/views/SocialMedia.vue'),
				children: [
					{
						path: 'brands',
						name: 'brand.home',
						component: () =>
							import('../domains/SocialMedia/views/Brands.vue'),
						meta: {
							title: 'Social Brands!',
						},
					},
					{
						path: 'brands/:id',
						name: 'brand.manage',
						component: () =>
							import('../domains/SocialMedia/views/Board.vue'),
						meta: {
							title: 'Manage Social Accounts',
						},
					},
				],
			},

			// Faqs
			{
				path: 'faq',
				name: 'faq.list',
				component: () => import('../domains/Faq/Faqs.vue'),
				meta: {
					title: 'Faq Listing',
				},
			},
			{
				path: 'faq/create',
				name: 'faq.create',
				component: () => import('../domains/Faq/CreateFaq.vue'),
				props: {
					isEdit: false,
				},
				meta: {
					title: 'Create Faq',
				},
			},
			{
				path: 'faq/:id/edit',
				name: 'faq.edit',
				component: () => import('../domains/Faq/CreateFaq.vue'),
				meta: {
					title: 'Edit Faq',
				},
				props: (route) => ({
					id: route.params.id,
					isEdit: true,
				}),
			},

			// Professional
			{
				path: 'professional',
				name: 'professional.list',
				component: () =>
					import('../domains/Professional/Professionals.vue'),
				meta: {
					title: 'Professional Listing',
				},
			},
			{
				path: 'professional/create',
				name: 'professional.create',
				component: () =>
					import('../domains/Professional/CreateProfessional.vue'),
				meta: {
					title: 'Create Professional',
				},
				props: {
					isEdit: false,
				},
			},
			{
				path: 'professional/:id/edit',
				name: 'professional.edit',
				component: () =>
					import('../domains/Professional/CreateProfessional.vue'),
				meta: {
					title: 'Edit Professional',
				},
				props: (route) => ({
					id: route.params.id,
					isEdit: true,
				}),
			},

			//Report
			{
				path: 'report',
				name: 'report.list',
				component: () => import('../domains/Report/Reports.vue'),
				meta: {
					title: 'Report Listing',
				},
			},
			{
				path: 'report/create',
				name: 'report.create',
				component: () => import('../domains/Report/CreateReport.vue'),
				meta: {
					title: 'Create Report',
				},
			},
			{
				path: 'report/:id/edit',
				name: 'report.edit',
				component: () => import('../domains/Report/CreateReport.vue'),
				meta: {
					title: 'Edit Report',
				},
				props: (route) => ({
					id: route.params.id,
				}),
			},

			//Blogs
			{
				path: 'blog',
				name: 'blog.list',
				component: () => import('../domains/Blog/Blogs.vue'),
				meta: {
					title: 'Blog Listing',
				},
			},
			{
				path: 'blog/create',
				name: 'blog.create',
				component: () => import('../domains/Blog/CreateBlog.vue'),
				meta: {
					title: 'Create Blog',
				},
			},
			{
				path: 'blog/:id/edit',
				name: 'blog.edit',
				component: () => import('../domains/Blog/CreateBlog.vue'),
				meta: {
					title: 'Edit Blog',
				},
				props: (route) => ({
					id: route.params.id,
				}),
			},

			//Templates
			{
				path: 'template',
				name: 'template.list',
				component: () => import('../domains/Template/Templates.vue'),
				meta: {
					title: 'Template Listing',
				},
			},

			//USER
			{
				path: 'project/users',
				name: 'project.users',
				component: () => import('../domains/User/Users.vue'),
				meta: {
					title: 'Manage project members',
				},
			},

			// PROJECT CREATE/EDIT

			{
				path: 'edit',
				name: 'project.edit',
				component: () => import('../domains/Project/CreateProject.vue'),
				meta: {
					title: 'Edit Project Details',
				},
				props: (route) => ({
					id: route.params.projectId,
				}),
			},

			//SEO
			{
				path: 'seo',
				name: 'seo.edit',
				component: () => import('../domains/Seo/UpdateSeo.vue'),
				meta: {
					title: 'Edit Page SEO',
				},
			},

			//Content Freshness
			{
				path: 'content-freshness',
				name: 'content-freshness',
				component: () =>
					import('../domains/ContentFreshness/ContentFreshness.vue'),
				meta: {
					title: 'Content Freshness',
				},
			},

			// *******************************************************************************************************
			// WEBSITE PAGES
			// *******************************************************************************************************

			{
				path: 'pages/about-us',
				name: 'about-us',
				component: () =>
					import('../domains/WebsitePages/AboutUs/AboutUs.vue'),
				meta: {
					title: 'About Us',
				},
			},

			//Gallery

			{
				path: 'pages/gallery',
				name: 'gallery',
				component: () =>
					import('../domains/WebsitePages/Gallery/GalleryLayout.vue'),
				children: [
					{
						path: 'pictures',
						name: 'pictures.list',
						component: () =>
							import(
								'../domains/WebsitePages/Gallery/components/picture/Pictures.vue'
							),
						meta: {
							title: 'Gallery',
						},
					},
					{
						path: 'videos',
						name: 'videos.list',
						component: () =>
							import(
								'../domains/WebsitePages/Gallery/components/video/Videos.vue'
							),
						meta: {
							title: 'Gallery',
						},
					},
					{
						path: 'sensitivity',
						component: () =>
							import(
								'../domains/WebsitePages/Gallery/components/SensitiveContent.vue'
							),
						meta: {
							title: 'Gallery',
						},
					},
				],
			},

			// Media Coverage
			{
				path: 'pages/media-coverage',
				name: 'mediaCoverage.list',
				component: () =>
					import(
						'../domains/WebsitePages/MediaCoverage/MediaCoverage.vue'
					),
				meta: {
					title: 'Media Coverage',
				},
			},

			// Forum
			{
				path: 'pages/forum',
				name: 'forum.list',
				component: () =>
					import('../domains/WebsitePages/Forum/Forum.vue'),
				meta: {
					title: 'Forum',
				},
			},

			// Home
			{
				path: 'pages/home',
				component: () =>
					import('../domains/WebsitePages/Home/Home.vue'),
				children: [
					{
						path: 'edit',
						name: 'home.edit',
						component: () =>
							import(
								'../domains/WebsitePages/Home/ManageContent.vue'
							),
						meta: {
							title: 'Edit Home Page Content',
						},
					},
					{
						path: 'shuffle',
						name: 'home.shuffle',
						component: () =>
							import(
								'../domains/WebsitePages/Home/ManageSections.vue'
							),
						meta: {
							title: 'Shuffle Sections Of Home Page',
						},
					},
				],
			},

			// Contact
			{
				path: 'pages/contact',
				name: 'contact.create',
				component: () =>
					import('../domains/WebsitePages/Contact/Contact.vue'),
			},

			//Domain
			{
				path: 'project',
				component: () => import('../domains/Domain/WebsiteLayout.vue'),
				children: [
					{
						path: 'domain',
						name: 'domain.edit',
						component: () =>
							import('../domains/Domain/UpdateDomain.vue'),
						meta: {
							title: 'Edit Domain',
						},
					},
				],
			},

			// Subsection Layout
			{
				path: 'customization',
				component: () =>
					import('../domains/SubsectionLayout/SubsectionLayout.vue'),
				children: [
					{
						path: 'theme',
						name: 'theme.list',
						component: () =>
							import('../domains/SubsectionLayout/Themes.vue'),
						meta: {
							title: 'Themes',
						},
					},
					{
						path: 'color',
						name: 'color.create',
						component: () =>
							import('../domains/SubsectionLayout/Colors.vue'),
						meta: {
							title: 'Color Customisation',
						},
					},
					{
						path: 'navbar',
						name: 'navbar.create',
						component: () =>
							import('../domains/SubsectionLayout/Navbar.vue'),
						meta: {
							title: 'Navbar',
						},
					},
					{
						path: 'footer',
						name: 'footer.create',
						component: () =>
							import('../domains/SubsectionLayout/Footer.vue'),
						meta: {
							title: 'Footer',
						},
					},
				],
			},
		],
		meta: {
			requiresAuth: true,
		},
		beforeEnter: (to, from, next) => {
			/* Chenges projectId in store, if the user is switching the project */
			if (store.state.app.projectId !== to.params.projectId)
				store.commit('app/setProjectId', to.params.projectId)

			next()
		},
	},

	{
		path: '/',
		name: 'home',
		meta: {
			requiresAuth: true,
			title: 'Home',
		},
		beforeEnter: (to, from, next) => {
			Vue.$cookies.isKey('x-auth-token')
				? next('/projects')
				: next('/login')
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../domains/Authentication/Login.vue'),
		beforeEnter: (to, from, next) => {
			Vue.$cookies.isKey('x-auth-token') ? next('/projects') : next()
		},
	},
	{
		path: '/sign-up',
		name: 'sign-up',
		component: () => import('../domains/Authentication/SignUp.vue'),
		beforeEnter: (to, from, next) => {
			Vue.$cookies.isKey('x-auth-token') ? next('/projects') : next()
		},
	},
	{
		path: '/verify-email',
		name: 'verify-email',
		component: () =>
			import('../domains/Authentication/AccountVerification.vue'),
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('../domains/Authentication/Logout.vue'),
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import('../domains/Authentication/ForgotPassword.vue'),
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import('../domains/Authentication/ResetPassword.vue'),
		beforeEnter: (to, from, next) => {
			const isEmail = to.query.email
			const isToken = to.query.token
			isEmail && isToken ? next() : next('/forgot-password')
		},
	},
	{
		path: '/projects',
		name: 'project.list',
		component: () => import('../domains/Project/Projects.vue'),
		meta: { requiresAuth: true, title: 'Uno Search' },
	},
	{
		path: '/project/create',
		name: 'project.create',
		component: () => import('../domains/Project/CreateProject.vue'),
		meta: {
			requiresAuth: true,
			title: 'Create New Project',
		},
	},
	{
		path: '/gmb',
		name: 'gmb.callback',
		component: () => import('../domains/GMB/Callback.vue'),
		meta: {
			requiresAuth: true,
			title: 'GMB Callback',
		},
	},
	{
		path: '/social/facebook/callback',
		name: 'social.facebook.callback',
		component: () =>
			import('../domains/SocialMedia/platforms/Facebook/Callback.vue'),
		meta: {
			requiresAuth: true,
			title: 'Facebook Callback',
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
	if (requiresAuth) {
		const doesntHaveToken = !Vue.$cookies.isKey('x-auth-token')
		if (doesntHaveToken)
			next({
				name: 'login',
			})

		const lastUpdated = store.state.app.lastUpdated

		if (
			lastUpdated === null ||
			(lastUpdated && lastUpdated - Date.now() > 1000 * 60 * 60 * 6)
		) {
			store.dispatch('app/setUserAndProjects')
		}
	}

	next()
})

export default router
