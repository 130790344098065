export default {
	orgData(state, orgList) {
		state.orgs = orgList
	},
	accountEmails(state, emails) {
		state.emails = emails
	},
	selectedOrg(state, org) {
		state.selectedOrg = org
	},
	orgEmail(state, email) {
		state.selectedAccountEmail = email
	},
	// reviewData(state, data) {
	// 	state.reviewData.averageRating = data.averageRating
	// 	state.reviewData.reviews = data.reviews
	// 	state.reviewData.totalReviews = data.totalReviewCount
	// },
	// questionData(state, data) {
	// 	state.faqData.questions = data.questions
	// },
	// answerData(state, data) {
	// 	state.faqData.questionReply = data.answers.filter(
	// 		(answer) => answer.author.type === 'MERCHANT'
	// 	)[0]
	// 		? data.answers.filter(
	// 				(answer) => answer.author.type === 'MERCHANT'
	// 		  )[0]
	// 		: null
	// 	state.faqData.answers = data.answers.filter(
	// 		(answer) => answer.author.type != 'MERCHANT'
	// 	)
	// },
	postData(state, data) {
		state.posts = data.localPosts
	},
	populateScheduledPosts(state, data) {
		state.scheduledPosts = data.data
		state.scheduledPostsPaginator = data.paginator
	},
	updateScheduledPosts(state, data) {
		state.scheduledPosts = state.scheduledPosts.map((post) =>
			post.id === data.id ? data : post
		)
	},
	deleteScheduledPost(state, postId) {
		state.scheduledPosts = state.scheduledPosts.filter(
			(post) => post.id != postId
		)
	},
	locationGroupData(state, locationsArray) {
		state.locationGroupLocations = locationsArray
	},
}
