<template>
	<v-app-bar app color="white" height="60" elevation="1">
		<div v-if="display">
			<v-app-bar-nav-icon
				@click="updateAppDrawer()"
				class="ma-0"
			></v-app-bar-nav-icon>
		</div>

		<v-toolbar-title v-if="$route.path === '/projects'">
			<v-img
				src="@/assets/img/logo/horizontal-logo.svg"
				max-height="40"
				contain
				position="center left"
			></v-img>
		</v-toolbar-title>
		<v-toolbar-title class="text-h6" v-else-if="$route.meta">
			{{ $route.meta.title }}
		</v-toolbar-title>
		<v-toolbar-title class="text-h6" v-else> Uno Search </v-toolbar-title>

		<v-spacer></v-spacer>

		<div class="d-flex align-center">
			<v-tooltip
				bottom
				color="white"
				v-if="currentRouteName && $vuetify.breakpoint.mdiAndUp"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="mr-3 white--text"
						elevation="1"
						color="#ce93d8ff"
						target="_blank"
						:href="`http://uno-theme-classic.preview.unosearch.net/p/${projectId}`"
					>
						Preview
						<v-icon class="ml-2" size="20">
							mdi-open-in-new
						</v-icon>
					</v-btn>
				</template>
				<span class="black--text">Preview the Website</span>
			</v-tooltip>

			<v-menu offset-y transition="slide-y-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-avatar
						color="#d5eaff"
						size="40"
						v-bind="attrs"
						v-on="on"
					>
						<span class="grey--text text--darken-1 text-subtitle-1">
							{{ nameInitialsForAvatar }}
						</span>
					</v-avatar>
				</template>
				<v-list>
					<v-list-item>
						<v-list-item-title
							@click="$router.push({ name: 'logout' })"
							style="cursor: pointer"
						>
							Logout
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<TabsFilter :tabs="tabs" :domain="domain"></TabsFilter>
	</v-app-bar>
</template>

<script>
import TabsFilter from './TabsFilter'
export default {
	props: {
		tabs: {
			type: Array,
		},
		display: {
			type: Boolean,
			default: true,
		},
		domain: {
			type: String,
		},
	},
	components: {
		TabsFilter,
	},
	computed: {
		nameInitialsForAvatar() {
			return this.$store.getters['app/nameInitialsForAvatar']
		},
		projectId() {
			return this.$store.state.app.projectId
		},
		currentRouteName() {
			return this.$route.name === 'dashboard'
		},
	},
	methods: {
		updateAppDrawer() {
			this.$emit('update-app-drawer-from-navbar')
		},
	},
}
</script>
