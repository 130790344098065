import mutations from './mutations.js'
import actions from './actions.js'

export default {
	namespaced: true,
	state: {
		projects: [],
		invites: [],
	},
	mutations,
	actions,
}
