export default {
	updatePaginator(state, value) {
		state.paginator = value
	},
	updateTestimonies(state, value) {
		state.testimonies = value
	},
	setIsEdit(state, editStatus) {
		state.isEdit = editStatus
	},
	alertMessage(state, message) {
		state.alert.message = message
	},
	updateTestimony(state, obj) {
		Object.keys(obj).forEach((key) => {
			state[key] = obj[key]
			if (state.errors[key]) {
				state.errors[key] = ''
			}
		})
	},
	clearErrors(state) {
		state.errors = {}
	},
	errors(state, errors) {
		Object.keys(errors).forEach((key) => {
			state.errors[key] = errors[key]
		})
		state.haveErrors = true
	},
	populateInitialData(state, obj) {
		Object.keys(obj).forEach((key) => {
			state[key] = obj[key]
			state.isEdit = true
			if (state.errors[key]) {
				state.errors[key] = ''
			}
		})
	},
	updateServices(state, emittedarr) {
		state.services = emittedarr
	},
	populateListingData(state, listingObj) {
		state.testimonies = listingObj.data
		state.paginator = listingObj.paginator
	},
	deleteTestimony(state, testimony) {
		state.testimonies.every(function (data, index) {
			if (data.id === testimony.id) {
				let arr = [...state.testimonies]
				arr.splice(index, 1)
				state.testimonies = arr
				return false // to break the loop
			} else {
				return true
			}
		})
	},
	clearState(state) {
		state.body = null
		state.givenBy = null
		state.date = null
		state.anonimity = false
		state.services = []
		state.isPublished = false
		state.videoURL = null
		state.starRating = null
		state.id = null
		state.errors = {
			body: null,
			givenBy: null,
			date: null,
		}
		state.alert = {
			show: null,
			message: null,
			error: false,
		}
		state.haveErrors = false
		state.todayDate = false
		state.isEdit = false
		state.paginator = {}
	},
	// recentlyAdded(state, obj) {
	// 	let dataArray = state.recentlyAdded
	// 	if (dataArray.length > 0) {
	// 		console.log(1)
	// 		console.log(obj)
	// 		dataArray.every(function (data) {
	// 			if (data.id === obj.id) {
	// 				console.log(2)
	// 				return // to break the loop
	// 			} else {
	// 				console.log(3)
	// 				return dataArray.push(obj)
	// 			}
	// 		})
	// 	} else {
	// 		console.log(4)

	// 		return dataArray.push(obj)
	// 	}
	// 	state.recentlyAdded = dataArray
	// },
	toggleStatus(state) {
		state.isPublished = !state.isPublished
	},
}
