export default {
	updateRadius(state, value) {
		state.radius = value
	},
	updateQuery(state, query) {
		state.query = query
	},
	updateMarkers(state, markers) {
		state.markers = markers
	},
	updateLoading(state, loading) {
		state.loading = loading
	},
	updateRanks(state, localRanks) {
		state.localRanks = localRanks
	},
	showResultsModal(state, show) {
		state.showResultsModal = show
	},
	showSelectLocationModal(state, show) {
		state.selectLocationModal = show
	},

	selectedLocation(state, location) {
		const selectedLocation = state.localRanks.find((object) => {
			return (
				object.location.lat === location.lat &&
				object.location.lng === location.lng
			)
		})
		state.selectedLocation = selectedLocation
	},
	updateSearchedForLocation(state, payload) {
		state.name = payload.name
		state.center = payload.location
		state.selectLocationModal = false
		state.localRanks = []
		state.selectedLocation = null
	},
	clearState(state) {
		state.markers = []
		state.center = {}
		state.query = ''
		state.radius = 1
		state.selectedLocation = null
	},
}
