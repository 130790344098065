export default {
	getListingData({ commit, rootState }) {
		this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/blogs`,
			})
			.then((response) => {
				let _data = response.data
				commit('blog/populateListingData', _data, { root: true })
			})
	},
	deleteBlog({ dispatch, rootState }, { blogId }) {
		this._vm.axios
			.delete(`/${rootState.app.projectId}/blogs/${blogId}`)
			.then(() => dispatch('getListingData'))
	},
}
