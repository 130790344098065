export default {
	updateProjectListing(state, obj) {
		const arr = state.projects
		arr.push(obj)
		state.projects = arr
	},
	populateProjectListingData(state, listingArray) {
		state.projects = listingArray
	},
	updateProjectInvites(state, obj) {
		const arr = state.invites
		arr.push(obj)
		state.invites = arr
	},
	populateProjectInvitesData(state, invitesArray) {
		state.invites = invitesArray
	},
	clearState(state) {
		state.projects = []
		state.invites = []
	},
}
