function resetLastUpdated(state) {
	state.lastUpdated = Date.now()
}
export default {
	setProjectId(state, value) {
		state.projectId = value
		resetLastUpdated(state)
	},
	setUser(state, user) {
		const { firstName, lastName } = user
		state.user.firstName = firstName
		state.user.lastName = lastName
		resetLastUpdated(state)
	},
	setProjects(state, projects) {
		state.projects = projects
		resetLastUpdated(state)
	},
	populateListingData(state, listingArray) {
		state.projects = listingArray
		resetLastUpdated(state)
	},
	resetLastUpdated,
}
