export default {
	validateTestimonyData: function ({ state, commit, dispatch }) {
		let testimony = state
		let isValid = true
		let errors = {}

		if (testimony.body === null) {
			errors.body = 'Body field should not be empty.'
			isValid = false
		} else {
			errors.body = ''
		}
		if (testimony.givenBy === null && !testimony.anonimity) {
			errors.givenBy = 'Either enter name or check anonymous'
			isValid = false
		} else {
			errors.givenBy = ''
		}
		if (testimony.date === null && !testimony.todayDate) {
			errors.date = 'Either select date or check today option'
			isValid = false
		} else {
			errors.date = ''
		}
		if (isValid === false) {
			commit('testimony/errors', errors, { root: true })
		} else {
			dispatch('saveTestimony', { root: true })
		}
	},
	saveTestimony: function ({ state, commit, dispatch, rootState }) {
		let payload = {
			body: state.body,
			givenBy: state.givenBy,
			anonimity: state.anonimity,
			date: state.todayDate
				? new Date().toISOString().substr(0, 10)
				: state.date,
			services: state.services.map((obj) => {
				return obj['id']
			}),
			videoURL: state.videoURL ? state.videoURL : null,
			starRating: state.starRating ? state.starRating : null,
		}
		return this._vm
			.axios({
				method: state.isEdit ? 'put' : 'post',
				url: state.isEdit
					? `/${rootState.app.projectId}/testimonies/${state.id}`
					: `/${rootState.app.projectId}/testimonies`,
				data: payload,
			})
			.then((response) => {
				let _data = response.data
				if (_data.success && _data.data != null) {
					commit('testimony/setIsEdit', true, { root: true }) // sets edit mode true
					commit(
						'testimony/updateTestimony',
						{ id: _data.data.id },
						{ root: true }
					)
					// commit('testimony/recentlyAdded', _data.data, {
					// 	root: true,
					// })
					const alertMessage = response.data.message
					commit('testimony/alertMessage', alertMessage, {
						root: true,
					})
					dispatch('getListingData', { root: true })
				}
			})
			.catch((error) => {
				const { data } = error.response
				if (data.statusCode == 400) {
					this.isSaving = false
					this._vm.$snackbar.notify({
						message: data.message,
						color: 'black',
					})
				}
			})
	},
	getInitialData: function ({ commit, rootState }, { id }) {
		return this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/testimonies/${id}`,
			})
			.then((response) => {
				let _data = response.data.data
				commit('testimony/populateInitialData', _data, { root: true })
			})
	},
	getListingData({ commit, rootState }) {
		return this._vm
			.axios({
				method: 'get',
				url: `/${rootState.app.projectId}/testimonies`,
			})
			.then((response) => {
				let _data = response.data
				commit('testimony/populateListingData', _data, { root: true })
			})
	},
	toggleStatus: function ({ commit, rootState }, state) {
		return this._vm
			.axios({
				method: 'post',
				url: `/${rootState.app.projectId}/testimonies/toggle-status/${state.id}`,
			})
			.then(() => {
				commit('testimony/toggleStatus', null, {
					root: true,
				})
			})
	},
	deleteTestimony({ commit, rootState }, { testimonyId }) {
		return this._vm
			.axios({
				method: 'delete',
				url: `/${rootState.app.projectId}/testimonies/${testimonyId}`,
			})
			.then((response) => {
				let _data = response.data.data
				commit('testimony/deleteTestimony', _data, { root: true })
				commit('testimony/clearState', null, { root: true })
			})
	},
}
