import mutations from './mutations.js'
import actions from './actions.js'

export default {
	namespaced: true,
	state: {
		userEmail: '',
		userRoles: [],
		projectUsers: [],
		pendingUserInvites: [],
	},
	mutations,
	actions,
}
