const abilityPlugin = {
	install: (Vue, { store }) => {
		if (!store) {
			throw new Error('Please provide vuex store.')
		}

		Vue.prototype.$ability = {
			/**
			 * Updates the state of the abilities with the new
			 * abilities received from backend
			 *
			 * @param { Object } obj An object
			 * @param { Array } obj.abilities New abilities from backend
			 * @returns { Void } Return nothing
			 */
			update: function ({ abilities = [] }) {
				store.commit(
					'ability/SET_ABILITIES',
					{ abilities },
					{ root: true }
				)
			},
		}

		/**
		 * Checks whether the user can access or
		 * can perform the requested action
		 *
		 * @param { String } requestedAction
		 * @param { String } requestedDomain
		 * @returns { Boolean } Can perform action or not
		 */
		Vue.prototype.$can = function (requestedAction, requestedDomain) {
			const abilities = store.state.ability.abilities

			const ability = abilities.find(
				(ability) => ability.domain === requestedDomain
			)

			if (!ability) {
				return false
			}

			return ability.permissions.includes(requestedAction)
		}
	},
}

export default abilityPlugin
