// import getters from "./getters.js";
import mutations from './mutations.js'
import actions from './actions.js'
const namespaced = true

export default {
	namespaced,
	state: {
		blogs: [],
		paginator: {},
	},
	//   getters,
	mutations,
	actions,
}
