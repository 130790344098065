import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
const namespaced = true

export default {
	namespaced,
	state: {
		preAuthorizationUrl:
			'https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/business.manage%20https://www.googleapis.com/auth/userinfo.email&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&state=',
		postAuthorizationUrl:
			'&redirect_uri=' +
			process.env.VUE_APP_GMB_REDIRECT_URL +
			'&client_id=' +
			process.env.VUE_APP_GMB_CLIENT_ID,
		orgs: null,
		selectedAccountEmail: null,
		selectedOrg: null,
		locationGroupLocations: [],
		posts: null,
		emails: [],
		scheduledPosts: null,
		scheduledPostsPaginator: null,
	},
	getters,
	mutations,
	actions,
}
