<template>
	<transition name="fade" mode="out-in">
		<router-view></router-view>
	</transition>
</template>

<script>
export default {
	name: 'App',
}
</script>

<style>
@import './assets/css/desktop.css';
</style>
